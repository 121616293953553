<template>
	<div>
		<div class="row">
			<div class="col-12 col-xl-6">
				<div class="card">
					<div class="card-header">
						<div>
							<h4 class="card-header-title">Create New Issue Category</h4>
						</div>
					</div>
					<div class="card-body">
						<div
							class="alert alert-danger alert-dismissible fade show"
							role="alert"
							v-if="errorMessage"
							>
							{{ errorMessage }}
						</div>
						<div>
							<form @submit.prevent="createCategory">
								<div class="row mt-4">
									<div class="col-12 col-md-12">
										<div class="form-group">
											<label class="form-label"> Category name </label>
											<input
												v-model.trim="category.name"
												required
												type="text"
												class="form-control"
												/>
										</div>
									</div>
								</div>
								<div class="row mt-4">
									<div class="col-12 col-md-12">
										<div class="form-group">
											<label class="form-label">
												Issue type
												<span class="font-italic">(Minimum of two issues)</span>
											</label>
											<div class="input-group mb-3">
												<input
													type="text"
													class="form-control"
													placeholder="Enter issue type"
													aria-label="Enter issue type"
													aria-describedby="button-addon"
													v-model.trim="issueType"
													/>
												<div class="input-group-append">
													<button
														class="btn btn-primary"
														:disabled="!issueType"
														type="button"
														id="button-addon"
														@click.prevent="addIssueToList"
														>
														Add
													</button>
												</div>
											</div>
										</div>

										<div
											class="list-group list-unstyled"
											v-if="category.issues.length"
											>
											<div
												v-for="(item, index) in category.issues"
												:key="index"
												class="px-0 py-1 d-flex justify-content-between"
												>
												{{ item }}
												<i
													class="fe fe-x text-danger pointer"
													@click="deleteIssueFromList(index)"
													></i>
											</div>
										</div>
									</div>
								</div>

								<div class="row"></div>

								<button
									type="submit"
									class="btn btn-primary mt-4"
									:disabled="
										processing || !category.name || !category.issues.length
									"
									>
									{{ processing ? 'Creating category...' : `Create Category` }}
								</button>
							</form>
						</div>
					</div>
				</div>
			</div>
		</div>

		<!-- CATEGORIES LIST -->
		<div>
			<div class="row mt-4">
				<div class="col-12">
					<div class="card">
						<div class="card-header justify-content-between">
							<h4 class="card-header-title">Categories List</h4>
						</div>
						<div class="card-body">
							<b-table
								striped
								hover
								selectable
								responsive
								show-empty
								empty-text="No category available"
								:items="categoriesList"
								:fields="fields"
								:per-page="perPage"
								:current-page="currentPage"
								:busy="loading"
								@row-clicked="onRowClicked"
								>
								<template #table-busy>
									<div class="text-center text-secondary my-2">
										<strong>Loading...</strong>
									</div>
								</template>
								<template #cell(category)="data">
									<span> {{ data.item.name }}</span>
								</template>
								<template #cell(issue_types)="data">
									<span
										v-for="option in data.item.options"
										:key="option.reference"
										class="badge badge-pill badge-secondary mx-1"
										>{{ option.name }}</span
										>
								</template>
								<template #cell(created_at)="data">
									<span>{{ data.item.created_at.split('T')[0] }}</span>
								</template>
							</b-table>

							<div class="card-footer" v-if="categoriesList.length">
								<b-pagination
									v-model="currentPage"
									:total-rows="categoriesList.length"
									:per-page="perPage"
									></b-pagination>
							</div>
						</div>
					</div>
				</div>
			</div>
		</div>
	</div>
</template>

<script>
export default {
  data () {
    return {
      processing: false,
      errorMessage: '',
      loading: false,
      category: {
        name: '',
        issues: []
      },
      issueType: '',
      categoriesList: [],
      fields: [
        {
          key: 'name',
          label: 'Category'
        },
        {
          key: 'issue_types',
          label: 'Issue Types'
        },
        {
          key: 'created_at',
          label: 'Date Created'
        }
      ],
      perPage: 10,
      currentPage: 1,
      settingsId: ''
    }
  },
  mounted () {
    this.fetchCategoriesBySettings()
  },
  methods: {
    async fetchTripRatingSettings (serviceId) {
      const tripRatingSettingsResponse = await this.axios.get(
        `/rating/settings/service-id/${serviceId}`
      )
      return tripRatingSettingsResponse.data.data
    },
    async fetchCategoriesBySettings () {
      this.loading = true

      const { reference } = await this.fetchTripRatingSettings(
        process.env.VUE_APP_TRIP_RATING_SERVICE_ID
      )
      this.settingsId = reference

      const categoriesResponse = await this.axios.get(
        `/rating/settings/${this.settingsId}/categories`
      )
      this.categoriesList = categoriesResponse.data?.data ?? []
      this.loading = false
    },
    async createCategory () {
      this.processing = true
      this.errorLoading = false

      const categoryPayload = {
        settings_id: this.settingsId,
        name: this.category.name
      }

      const response = await this.axios.post(
        '/rating/categories',
        categoryPayload
      )
      const newlyCreatedCategory = response.data.data

      const issueTypesRequests = this.category.issues.map((issue) =>
        this.axios.post('/rating/options', {
          name: issue,
          settings_id: newlyCreatedCategory.settings_id,
          category_id: newlyCreatedCategory.reference
        })
      )

      Promise.all(issueTypesRequests)
        .then(() => {
          this.$swal({
            icon: 'success',
            title: 'Category Created',
            text: 'Category has been created successfully',
            showCloseButton: true
          })
          this.fetchCategoriesBySettings()
          this.category.name = ''
          this.category.issues = []
        })
        .catch((e) => {
          this.$swal({
            icon: 'error',
            title: 'Error Occured',
            text: 'An error occured, please review',
            showCloseButton: true
          })

          let msg = e.toString()
          if (e && e.response && e.response.data && e.response.data.message) {
            msg = e.response.data.message
          }

          this.errorMessage = msg
          this.errorLoading = true
          this.processing = false
        })
        .finally(() => (this.processing = false))
    },
    addIssueToList () {
      this.category.issues.unshift(this.issueType)
      this.issueType = ''
    },
    deleteIssueFromList (indexOfItem) {
      this.category.issues = this.category.issues.filter(
        (item, index) => index !== indexOfItem
      )
    },
    onRowClicked (category) {
      this.$router.push({
        name: 'ShowCategory',
        params: { categoryId: category.reference }
      })
    }
  }
}
</script>
